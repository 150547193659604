@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-color-scheme: light) {
    body, html, :root {
        background-color: #efeff4;
    }
}

@media (prefers-color-scheme: dark) {
    body, html, :root {
        background-color: #000;
    }
    .logo {
        -webkit-filter: invert(1);
        filter: invert(1);
    }
    .spaceship {
        -webkit-filter: invert(1);
        filter: invert(1);
    }
}

@media only screen and (max-width: 800px) {
  .spaceship {
    width: 90%;
  }
}
@media only screen and (min-width: 801px) {
    .spaceship {
      width: 50%;
    }
}

.spaceship {
    display: block;
    padding-top: 100px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /* No Select */
    touch-action: none;
    pointer-events: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
  
  .flying {
    -webkit-animation: flying 8s infinite;
    animation: flying 8s infinite;
  }
  
  @keyframes flying {
    0% {
      top: 0px;
    }
    10% {
      top: -10px;
    }
    20% {
      top: 10px;
    }
    30% {
      top: -5px;
    }
    40% {
      top: 5px;
    }
    50% {
      top: 0px;
    }
    60% {
      top: 10px;
    }
    70% {
      top: -5px;
    }
    80% {
      top: 10px;
    }
    100% {
      top: 0px;
    }
  }

  .custom-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .custom-scrollbar {
    scrollbar-width: none; /* Firefox */
  }
